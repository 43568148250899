/* eslint-disable prettier/prettier */
export default [
  {
    path: "/packages",
    name: "packages",
    component: () => import("@/views/company/Packages/Packages.vue"),
    meta: {
      action: "read",
      resource: "CompanyAdmin",

      pageTitle: "packages",
      breadcrumb: [
        {
          text: "comppanySettings",
          active: true,
        },
        {
          text: "packages",
          active: true,
        },
      ],
    },
  },
];
