/* eslint-disable prettier/prettier */
export default [
  {
    action: "read",
    resource: "CompanyAdmin",
    path: "/company/general",
    name: "general-settings",
    component: () => import("@/views/company/Settings/Settings.vue"),
    meta: {
      action: "read",
      resource: "CompanyAdmin",
      pageTitle: "generalSettings",
      breadcrumb: [
        {
          text: "comppanySettings",
          active: true,
        },
        {
          text: "generalSettings",
          active: true,
        },
      ],
    },
  },
];
