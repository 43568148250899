/* eslint-disable prettier/prettier */
export default [
  {
    path: "/plants",
    name: "plants",
    component: () => import("@/views/Plants/PlantList.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "plants",
      breadcrumb: [
        {
          text: "plants",
          active: true,
        },
      ],
    },
  },

  {
    path: "/formula/ingredients",
    name: "ingredients",
    component: () => import("@/views/Plants/Ingredients/Ingredient.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredients",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/formula/nutrients",
    name: "nutrients",
    component: () => import("@/views/Plants/Nutrients/Nutrients.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "nutrients",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/formula/nutrient-equations",
    name: "nutrientEquations",
    component: () => import("@/views/Plants/Nutrients/NutrientEquations.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "nutrientEquations",
      breadcrumb: [
        {
          to: "/",
          text: "nutrients",
          active: true,
        },
        {
          to: "nutrientEquations",
          text: "nutrientEquations",
        },
      ],
    },
  },
  {
    path: "/formula/additional-values",
    name: "additionalValues",
    component: () => import("@/views/Plants/Nutrients/AdditionalValues.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "additionalValues",
      breadcrumb: [
        {
          to: "/",
          text: "nutrients",
          active: true,
        },
        {
          to: "additionalValues",
          text: "additionalValues",
        },
      ],
    },
  },
  //nutrientMatrix
  {
    path: "/formula/nutrient-matrix",
    name: "nutrientMatrix",
    component: () => import("@/views/Plants/Nutrients/NutrientMatrix.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "nutrientMatrix",
      breadcrumb: [
        {
          to: "nutrients",
          text: "nutrients",
          active: true,
        },
        {
          to: "nutrientMatrix",
          text: "nutrientMatrix",
          active: true,
        },
      ],
    },
  },
  {
    path: "/formula/compare",
    name: "compareFormulas",
    component: () =>
      import("@/views/Plants/Formulas/Compare/ProductionFormulasCompare.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "compareFormulas",
      breadcrumb: [
        {
          to: "compareFormulas",
          text: "compareFormulas",
          active: true,
        },
      ],
    },
  },
  // nutrientUnits
  {
    path: "/formula/nutrient-units",
    name: "nutrientUnits",
    component: () => import("@/views/Plants/Nutrients/NutrientUnits.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "nutrients",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: "nutrientUnits",
          text: "nutrientUnits",
        },
      ],
    },
  },
  {
    path: "/formula/ingredients/prices",
    name: "ingredientsPrices",
    component: () => import("@/views/Plants/Ingredients/Prices.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredientPrices",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/formula/ingredients/change-code",
    name: "ingredientCodeChange",
    component: () =>
      import("@/views/Plants/Ingredients/IngredientCodeChange.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredientCodeChange",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/formula/ingredients/filter",
    name: "ingredientFilter",
    component: () => import("@/views/Plants/Ingredients/IngredientFilter.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "ingredientFilter",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: "/ingredients",
          text: "ingredients",
        },
        {
          to: ":id",
          text: ":id",
        },
      ],
    },
  },
  {
    path: "/formula/ingredients/copy",
    name: "copyIngredientNutrientValues",
    component: () =>
      import("@/views/Plants/Ingredients/CopyIngredientNutrientValues.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "copyIngredientNutrientValues",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
        {
          to: "/copyIngredientNutrientValues",
          text: "copyIngredientNutrientValues",
        },
      ],
    },
  },
  {
    path: "/production-formulas/",
    name: "productionFormulas",
    component: () =>
      import(
        "@/views/Plants/Formulas/ProductionFormula/ProductionFormulaList.vue"
      ),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "productionFormulas",
      breadcrumb: [
        {
          to: "/",
          text: "",
        },
      ],
    },
  },
  {
    path: "/production-formulas-archive/",
    name: "productionFormulasArchive",
    component: () =>
      import(
        "@/views/Plants/Formulas/ProductionFormula/ProductionFormulaArchiveList.vue"
      ),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "productionFormulasArchive",
      breadcrumb: [
        {
          to: "/",
          text: "",
        },
      ],
    },
  },

  {
    path: "/formulas/single-formula",
    name: "plantFormulas",
    component: () =>
      import("@/views/Plants/Formulas/FormulaList/FormulaList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Single",

      pageTitle: "formulas",
      breadcrumb: [
        {
          to: "/",
          text: "formulas",
          active: true,
        },
        {
          text: "singleFormula",
          active: true,
        },
      ],
    },
  },
  {
    path: "/plant/:id/formula/:formulaid/multiFormula",
    name: "multiFormulaView",
    component: () =>
      import("@/views/Plants/Formulas/MultiFormula/View/MultiView.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Multi",
      navActiveLink: "plantFormulas",
      pageTitle: "multiFormulaInformation",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
      ],
    },
  },
  {
    path: "/formulas/multi-formula",
    name: "multiFormulas",
    component: () =>
      import("@/views/Plants/Formulas/MultiFormula/MultiFormulaList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Multi",

      pageTitle: "multiFormulas",
      breadcrumb: [
        {
          to: "/",
          text: "",
        },
      ],
    },
  },
  {
    path: "/plant/:id/formula/:formulaid/singleFormula",
    name: "formulaView",
    component: () =>
      import("@/views/Plants/Formulas/FormulaView/FormulaView.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Single",
      navActiveLink: "plantFormulas",

      pageTitle: "formulaInformation",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
      ],
    },
  },

  {
    path: "/plant/formula/template",
    name: "formulaTemplates",
    component: () =>
      import("@/views/Plants/Formulas/Templates/TemplateList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "plantFormulas",

      pageTitle: "formulaTemplates",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
      ],
    },
  },

  {
    path: "/plant/:id/production-formula/:formulaid/",
    name: "productionFormulaView",
    component: () =>
      import(
        "@/views/Plants/Formulas/ProductionFormula/ProductionFormulaView.vue"
      ),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "productionFormulas",

      pageTitle: "productionFormulas",
      breadcrumb: [
        {
          to: "/plants",
          text: "Plants",
        },
      ],
    },
  },
];
