/* eslint-disable prettier/prettier */
export default [
  {
    path: "/production-formulas-compare",
    name: "productionFormulasCompare",
    component: () =>
      import("@/views/GeneralActions/ProductionFormulasCompare.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "generalActions",

      pageTitle: "generalActions",
      breadcrumb: [],
    },
  },
];
