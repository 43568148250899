/* eslint-disable prettier/prettier */
export default [
  {
    path: "/alternativeNutrients",
    name: "alternativeNutrients",
    component: () => import("@/views/Analyze/AlternativeNutrients.vue"),
    meta: {
      action: "read",
      resource: "Auth",

      pageTitle: "analyze",
      breadcrumb: [
        {
          text: "analyze",
          active: true,
        },
      ],
    },
  },
  {
    path: "/formula-verification",
    name: "formulaVerification",
    component: () => import("@/views/Analyze/FormulaVerification.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "analyze",

      pageTitle: "formulaVerification",
      breadcrumb: [
        {
          to: "/plants",
          text: "analyze",
          active: true,
        },
        { text: "formulaVerification", active: true },
      ],
    },
  },
  {
    path: "/periodicIngredientCalculation",
    name: "periodicIngredientCalculation",
    component: () =>
      import("@/views/Analyze/PeriodicIngredientCalculation.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "analyze",

      pageTitle: "hammaddeIhtiyac",
      breadcrumb: [
        {
          to: "/plants",
          text: "analyze",
          active: true,
        },
        { text: "periodicIngredientCalculation", active: true },
      ],
    },
  },
];
