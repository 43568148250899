/* eslint-disable prettier/prettier */
export default [
  {
    path: "/ingredient-tags",
    name: "ingredientTags",
    component: () => import("@/views/Plants/Ingredients/Tag/TagList.vue"),
    meta: {
      action: "read",
      resource: "Auth",
      pageTitle: "tagActions",
      breadcrumb: [
        {
          text: "tagActions",
          active: true,
        },
      ],
    },
  },
  {
    path: "/nutrient-tags",
    name: "nutrientTags",
    component: () => import("@/views/Plants/Nutrients/Tag/TagList.vue"),
    exact: true,
    meta: {
      action: "read",
      resource: "Auth",
      navActiveLink: "tagActions",

      pageTitle: "tagActions",
      breadcrumb: [],
    },
  },
];
